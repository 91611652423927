import {
  Button,
  Grid,
  Modal,
  NumberInput,
  Select,
  Textarea,
  TextInput,
} from "@mantine/core";
import { useForm } from "@mantine/form";

import React, { useEffect, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { api } from "../../api";
import { createChannel, updateChannel } from "../../queries/channelQueries";
import { ChannelProps } from "../../typing/channels";

export const ChannelForm = (props: any) => {
  const queryClient = useQueryClient();

  const { mutate: mutCreateChannel } = useMutation(createChannel, {
    onSuccess: (result) => {
      queryClient.invalidateQueries();
      props.onReset();
    },
  });

  const { mutate: mutUpdateChannel } = useMutation(updateChannel, {
    onSuccess: (result) => {
      queryClient.invalidateQueries();
      props.onReset();
    },
  });
  useEffect(() => {
    if (props.data) setValues(props.data);
  }, [props.data]);
  const form = useForm({
    initialValues: {
      list_order: 0,
      name: "",
      short_description: "",
      long_description: "",
      thumbnail: "",
      cover_image: "",
      language: "",
      number_of_posts: 0,
      number_of_followers: 0,
      share_text: "",
      status: "",
    } as ChannelProps,
    validate: {},
  });

  const { onSubmit, reset, errors, values, setFieldValue, setValues } = form;

  return (
    <Modal
      opened={props.isOpenModal}
      onClose={() => {
        reset();
        props.setOpenForm(false);
      }}
      title={props.data ? "Update Channel" : "Create New Channel"}
      centered
      size={"xl"}
      closeOnClickOutside={false}
    >
      <form
        onSubmit={onSubmit((payload) => {
          if (payload.channel_id) {
            mutUpdateChannel({ payload, id: payload.channel_id });
          } else mutCreateChannel(payload);
        })}
      >
        <Grid justify={"flex-start"}>
          <Grid.Col span={6}>
            <TextInput
              label="Channel Name"
              placeholder="Channel Name"
              data-autofocus
              value={values.name}
              onChange={(event) =>
                setFieldValue("name", event.currentTarget.value)
              }
            />
          </Grid.Col>
          <Grid.Col span={6}>
            <TextInput
              label="Short Description"
              placeholder="Short Description"
              data-autofocus
              value={values.short_description}
              onChange={(event) =>
                setFieldValue("short_description", event.currentTarget.value)
              }
            />
          </Grid.Col>
          <Grid.Col span={6}>
            <Textarea
              label="Long Description"
              placeholder="Long Description"
              data-autofocus
              value={values.long_description}
              onChange={(event) =>
                setFieldValue("long_description", event.currentTarget.value)
              }
            />
          </Grid.Col>
          <Grid.Col span={6}>
            <NumberInput
              label="List Order"
              placeholder="List Order"
              data-autofocus
              value={values.list_order}
              onChange={(value) => setFieldValue("list_order", value)}
              inputMode="numeric"
            />
          </Grid.Col>
          <Grid.Col span={6}>
            <TextInput
              label="Thumbnail"
              placeholder="Thumbnail"
              data-autofocus
              value={values.thumbnail}
              onChange={(event) =>
                setFieldValue("thumbnail", event.currentTarget.value)
              }
            />
          </Grid.Col>
          <Grid.Col span={6}>
            <TextInput
              label="Cover Image"
              placeholder="Cover Image"
              data-autofocus
              value={values.cover_image}
              onChange={(event) =>
                setFieldValue("cover_image", event.currentTarget.value)
              }
            />
          </Grid.Col>
          <Grid.Col span={6}>
            <TextInput
              label="Language"
              placeholder="Language"
              data-autofocus
              value={values.language}
              onChange={(event) =>
                setFieldValue("language", event.currentTarget.value)
              }
            />
          </Grid.Col>
          <Grid.Col span={6}>
            <NumberInput
              label="Number of Posts"
              placeholder="Number of Posts"
              data-autofocus
              value={values.number_of_posts}
              onChange={(value) => setFieldValue("number_of_posts", value)}
            />
          </Grid.Col>
          <Grid.Col span={6}>
            <NumberInput
              label="Number of Followers"
              placeholder="Number of Followers"
              data-autofocus
              value={values.number_of_followers}
              onChange={(value) => setFieldValue("number_of_followers", value)}
            />
          </Grid.Col>
          <Grid.Col span={6}>
            <TextInput
              label="Share Text"
              placeholder="Share Text"
              data-autofocus
              value={values.share_text}
              onChange={(event) =>
                setFieldValue("share_text", event.currentTarget.value)
              }
            />
          </Grid.Col>
          <Grid.Col span={6}>
            <Select
              label="Status"
              placeholder="Channel Status"
              value={values.status}
              data={[
                { value: "list", label: "List" },
                { value: "unlist", label: "Un-List" },
                { value: "block", label: "Block" },
              ]}
              onChange={(value) => setFieldValue("status", value)}
            />
          </Grid.Col>
          <Grid.Col span={3} offset={6}>
            <Button
              fullWidth
              onClick={reset}
              mt="md"
              color={"red"}
              variant={"light"}
            >
              Clear
            </Button>
          </Grid.Col>
          <Grid.Col span={3}>
            <Button fullWidth type="submit" mt="md" variant="light">
              Submit
            </Button>
          </Grid.Col>
        </Grid>
      </form>
    </Modal>
  );
};
