import { MutateFunction } from "react-query";
import { api } from "../api";

export const getFeedbacks = async () => {
  return await api.get("feedback/list");
};

export const deleteFeedback: MutateFunction<string, any, number> = (id) => {
  return api.delete(`app/feedback/${id}`);
};
