import { MutateFunction } from "react-query";
import { api } from "../../../api";

export const getCourseDetails = async () => {
  return await api.get(`course`);
};

export const updateCourse: MutateFunction<
  CourseProps,
  Partial<CourseProps>,
  { payload: CourseProps; id: number }
> = async ({ payload, id }) => {
  return api.patch(`course/${id}`, undefined, payload);
};

export const createCourse: MutateFunction<
  unknown,
  CourseProps,
  { payload: CourseProps }
> = async ({ payload }) => {
  return api.post(`course`, undefined, payload);
};

export const deleteCourse: MutateFunction<
  unknown,
  any,
  { id: number }
> = async ({ id }) => {
  return api.delete(`course/${id}`);
};
