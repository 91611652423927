import { MutateFunction } from "react-query";
import { api } from "../../../api";

export const getSemesterDetails = async () => {
  return await api.get(`semester`);
};

export const updateSemester: MutateFunction<
  SemesterProps,
  Partial<SemesterProps>,
  { payload: SemesterProps; id: number }
> = async ({ payload, id }) => {
  return api.patch(`semester/${id}`, undefined, payload);
};

export const createSemester: MutateFunction<
  unknown,
  SemesterProps,
  { payload: SemesterProps }
> = async ({ payload }) => {
  return api.post(`semester`, undefined, payload);
};

export const deleteSemester: MutateFunction<
  unknown,
  any,
  { id: number }
> = async ({ id }) => {
  return api.delete(`semester/${id}`);
};
