import React, { SyntheticEvent, useCallback, useEffect, useState } from "react";
import {
  createStyles,
  Table,
  Anchor,
  ScrollArea,
  Grid,
  Button,
  Divider,
  ActionIcon,
} from "@mantine/core";
import { Edit, Archive } from "tabler-icons-react";

import { useMutation, useQuery, useQueryClient } from "react-query";

import { deleteUser, getAllAppUsers } from "../../queries/userQuery";
import { userProps } from "../../typing/users";

const Users = () => {
  // const [isOpenForm, setOpenForm] = useState(false);
  // const [updateData, setUpdateData] = useState<FeedProps | null>(null);

  const queryClient = useQueryClient();

  const { isLoading, data: userData = [] } = useQuery("users", getAllAppUsers, {
    staleTime: 60000,
    refetchOnWindowFocus: false,
  });

  const { mutate: mutDeleteUser } = useMutation(deleteUser, {
    onSuccess: (newData) => {
      queryClient.invalidateQueries();
    },
  });

  const rows =
    userData.data &&
    userData.data.map((row: userProps) => {
      return (
        <tr key={row.user_id}>
          <td>{row.first_name}</td>
          <td>{row.last_name}</td>
          <td>{row.user_id}</td>
          <td>{row.mobile_number_prefix}</td>
          <td>{row.mobile_number}</td>
          <td>{row.number_verified?.toString()}</td>
          <td>{row.email}</td>
          <td>{row.state}</td>
          <td>{row.district}</td>

          <td>{row.whatsapp_join_status?.toString()}</td>
          <td>{row.task_1_complete_status?.toString()}</td>
          <td>{row.task_2_complete_status?.toString()}</td>
          <td>{row.account_status}</td>
          <td>{row.total_app_usage_time}</td>
          <td>{row.last_used_date}</td>
          <td>{row.reg_date}</td>
          <td>{row.reg_time}</td>
          <td>{row.firebase_id}</td>
          <td>{row.occupational_status}</td>
          <td>{row.university_name}</td>
          <td>{row.college}</td>
          <td>{row.course_name}</td>
          <td>{row.stream_name}</td>
          <td>{row.semester_name}</td>
          <td>{row.year}</td>
          <td>{row.other_1}</td>
          <td>{row.other_2}</td>

          <td>
            <Grid columns={2}>
              {/* <Grid.Col span={1}>
                                <ActionIcon
                                    title="Update"
                                    onClick={() => onUpdateData({ ...row })}
                                >
                                    <Edit
                                        size={25}
                                        strokeWidth={2}
                                        color={"green"}
                                    />
                                </ActionIcon>
                            </Grid.Col> */}
              <Grid.Col span={1}>
                <ActionIcon
                  title="Delete"
                  onClick={() => mutDeleteUser(row.user_id)}
                >
                  <Archive size={25} strokeWidth={2} color={"red"} />
                </ActionIcon>
              </Grid.Col>
            </Grid>
          </td>
        </tr>
      );
    });

  if (isLoading) {
    // TODO loader on data loading
    return null;
  }
  return (
    <>
      <Grid justify={"flex-end"}>
        {/* <Grid.Col lg={1}>
          <Button
            variant="light"
            onClick={(e: SyntheticEvent) => setOpenForm(true)}
          >
            Create
          </Button>
        </Grid.Col> */}

        <Grid.Col>
          <Divider />
          <ScrollArea
            offsetScrollbars
            style={{ height: 700, padding: 15 }}
            scrollHideDelay={1000}
          >
            <Table
              sx={{ minWidth: 800 }}
              verticalSpacing="xs"
              horizontalSpacing={"xl"}
            >
              <thead>
                <tr>
                  <th>First Name</th>
                  <th>Last Name</th>
                  <th>User ID</th>
                  <th>Country Code</th>
                  <th>Phone Number</th>
                  <th>Number Verified</th>
                  <th>Email</th>
                  <th>State</th>
                  <th>District</th>
                  <th>Whatsapp Join Status</th>
                  <th>Task 1 Complete Status</th>
                  <th>Task 2 Complete Status</th>

                  <th>Account Status</th>
                  <th>Total App Usage</th>
                  <th>Last Used Date</th>
                  <th>Reg. Date</th>
                  <th>Reg. Time</th>
                  <th>Firebase Id</th>
                  <th>Occupational Status</th>
                  <th>University</th>
                  <th>College</th>
                  <th>Course</th>
                  <th>Stream</th>
                  <th>Semester</th>
                  <th>Year</th>
                  <th>Other 1</th>
                  <th>Other 2</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>{rows}</tbody>
            </Table>
          </ScrollArea>
        </Grid.Col>
      </Grid>
    </>
  );
};

export default Users;
