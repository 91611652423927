import { MutateFunction } from "react-query";
import { api } from "../api";
import { NotesProps } from "../typing/notesTypes";

export const getAllNotes = async ({ queryKey }: any) => {
  const [_, page] = queryKey;
  return await api.get(`notes/list/filters?offset=${page}&limit=10`);
};

export const createNewNote: MutateFunction<
  NotesProps,
  Partial<NotesProps>,
  any
> = async (payload) => {
  return api.post("notes/new-note", undefined, payload);
};

export const updateNote: MutateFunction<
  NotesProps,
  Partial<NotesProps>,
  { payload: any; id: number }
> = async ({ payload, id }) => {
  let stringifyPayload = Object.assign(payload, {
    normal_post: JSON.stringify(payload.normal_post),
    flash_card: JSON.stringify(payload.flash_card),
    quiz_fight: JSON.stringify(payload.quiz_fight),
    quiz: JSON.stringify(payload.quiz),
    exam: JSON.stringify(payload.exam),
    poll: JSON.stringify(payload.poll),
    youtube: JSON.stringify(payload.youtube),
    video: JSON.stringify(payload.video),
    audio: JSON.stringify(payload.audio),
  });

  return api.patch(`notes/update/${id}`, undefined, stringifyPayload);
};
export const getHashtags = async () => {
  return api.get("feed/hashtags");
};

export const getCategories = async () => {
  return api.get("feed/categories");
};

export const deletePost: MutateFunction<string, any, number> = (id) => {
  return api.delete(`notes/delete/${id}`);
};
