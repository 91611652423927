class API {
  private _baseUrl: string;
  private _SESSION_STORAGE_ID = "@vivar_refresh_token";
  constructor(baseUrl?: string) {
    this._baseUrl = baseUrl || "https://api.ccevnts.in/v1";
  }

  private formatDestinationUrl = (
    endpoint: string,
    query: { [key: string]: any } = {}
  ): string => {
    const remoteUrl = endpoint.startsWith("http")
      ? endpoint
      : `${this._baseUrl}/${endpoint}`;
    if (Object.keys(query).length === 0) {
      return remoteUrl;
    }

    const paramsKeyValuePair = Object.keys(query).map((key) => {
      if (query[key] === null) {
        return key;
      }
      if (Array.isArray(query[key])) {
        return `${key}=${encodeURIComponent(query[key].join(","))}`;
      }
      return `${key}=${encodeURIComponent(query[key])}`;
    });

    const joinedParams = paramsKeyValuePair.reduce(
      (acl, curr) => `${acl}&${curr}`
    );

    return `${remoteUrl}?${joinedParams}`;
  };

  private fetchJson = async (
    endpoint: string,
    method: string,
    query?: Object,
    body?: Object | FormData
  ) => {
    const destination = this.formatDestinationUrl(endpoint, query);
    if (method !== "get" && method !== "delete" && typeof body !== "object") {
      throw new Error("The body must be an object");
    }

    const bodyIsFormData = body instanceof FormData;

    const authToken = localStorage.getItem(this._SESSION_STORAGE_ID);
    const authHeaders = authToken ? {
      ["Authorization"]: "Bearer " + authToken,
    } : null;
    const headers = Object.assign(
      {},
      authHeaders,
      bodyIsFormData ? undefined : { "Content-Type": "application/json" }
    );

    const fetchOptions: RequestInit = {
      method,
      headers,
    };

    if (method.toLowerCase() !== "get") {
      if (bodyIsFormData) {
        fetchOptions.body = body as FormData;
      } else {
        fetchOptions.body = JSON.stringify(body);
      }
    }

    try {
      const response = await fetch(destination, fetchOptions);
      const jsonResult = await response.json();
      if (response.status >= 200 && response.status <= 300) {
        return await jsonResult;
      }
      if (response.status === 401) {
        return Promise.reject(jsonResult);

        // TODO
      }
      if (response.status === 403) {
        return await jsonResult;
        // throw jsonResult.message;
        //   if(jsonResult.message==="You are not authorized for this action")

        // {
        //   const error="You are not authorized for this action"
        //   return error;
        // console.log("403,You are not authorized for this action");
        // throw new Error("You are not authorized for this action")
        // }

        // return error
        // throw new Error("You are not authorized for this action")

        // TODO
      }

      return Promise.reject(jsonResult);
    } catch (error) {
      console.log("error>>", error);
      throw error;
    }
  };

  public get = async (endpoint: string, query?: Object) => {
    return await this.fetchJson(endpoint, "get", query);
  };

  public post = async (
    endpoint: string,
    query?: Object,
    body?: Object | FormData
  ) => {
    return await this.fetchJson(endpoint, "post", query, body);
  };

  public patch = async (
    endpoint: string,
    query?: Object,
    body?: Object | FormData
  ) => {
    return await this.fetchJson(endpoint, "PATCH", query, body);
  };

  public delete = async (endpoint: string, query?: Object) => {
    return await this.fetchJson(endpoint, "delete", query);
  };

  public setAuthToken = (token: string): void => {
    localStorage.setItem(
      this._SESSION_STORAGE_ID,
      JSON.stringify({ "x-access-key": token })
    );
  };

  public getAuthToken = (): string | null => {
    const token = localStorage.getItem(this._SESSION_STORAGE_ID);

    if (token) {
      const access_token = JSON.parse(token)["x-access-key"];

      return access_token;
    }
    return null;
  };

  public clearAuth = (): void => {
    localStorage.removeItem(this._SESSION_STORAGE_ID);
  };

  public setBaseUrl = (url: string): void => {
    this._baseUrl = url;
  };
}

export const api = new API();
