import React from "react";
import { MutateFunction } from "react-query";
import { api } from "../api";
import { AppSetupProps } from "../typing/appSetup";

export const getAppSetupDetails = async () => {
  return await api.get(`app/settings`);
};

export const updateAppSetup: MutateFunction<
  AppSetupProps,
  Partial<AppSetupProps>,
  { payload: AppSetupProps; id: number }
> = async ({ payload, id }) => {
  return api.patch(`app/settings/${id}`, undefined, payload);
};
