import {
  Button,
  Container,
  Grid,
  Modal,
  SegmentedControl,
  Select,
  Switch,
  Text,
  TextInput,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { useEffect, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  createUniversity,
  getAllData,
  getUniversityById,
  updateUniversity,
} from "./queries";
import { Trash } from "tabler-icons-react";

export const Form = (props: any) => {
  const queryClient = useQueryClient();
  const [courseData, setCourseData] = useState<CourseDataProps[]>([]);
  const [course, setCourse] = useState<string | null>();
  const [selectedCourse, setSelectedCourse] =
    useState<CourseDataProps | null>();
  const [stream, setStream] = useState<string | null>();
  const [selectedStream, setSelectedStream] =
    useState<StreamDataProps | null>();
  const [semester, setSemester] = useState<string | null>();
  const [selectedSemester, setSelectedSemester] =
    useState<SemesterDataProps | null>();
  const [subject, setSubject] = useState<string | null>();
  const [selectedSubject, setSelectedSubject] =
    useState<SubjectDataProps | null>();

  const [mappingId, setMappingId] = useState<number[]>([]);

  const { isLoading, data: streamData = [] } = useQuery(
    "university",
    getAllData,
    {
      staleTime: 60000,
      refetchOnWindowFocus: false,
    }
  );

  let id = props?.data?.id;

  const { data: streamValue = [] } = useQuery(
    ["university", id],
    getUniversityById,
    {
      staleTime: 60000,
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    if (streamValue?.[0]?.courses?.length) {
      let crsDt = streamValue?.[0]?.courses?.map((sv: any) => {
        console.log(sv);

        return {
          id: sv.course_id,
          name: sv.course_name,
          streams: sv.streams?.map((s: any) => {
            return {
              id: s.stream_id,
              name: s.stream_name,
              semesters: s.semesters?.map((sem: any) => {
                return {
                  id: sem.semester_id,
                  sem: sem.semester_name,
                  subjects: sem?.subjects?.map((sub: any) => {
                    setMappingId([...mappingId, sub.mapping_id]);
                    return {
                      id: sub.subject_id,
                      name: sub.subject_name,
                    };
                  }),
                };
              }),
            };
          }),
        };
      });

      setCourseData([...crsDt]);
    }
  }, [streamValue]);
  console.log(courseData);

  const { mutate: mutUpdateUniversity } = useMutation(updateUniversity, {
    onSuccess: (result) => {
      queryClient.invalidateQueries();
      props.onReset();
    },
  });

  const { mutate: mutCreateUniversity } = useMutation(createUniversity, {
    onSuccess: (result) => {
      queryClient.invalidateQueries();
      props.onReset();
    },
  });
  useEffect(() => {
    if (props.data) setValues(props.data);
  }, [props.data]);

  const form = useForm({
    initialValues: {
      name: "",
      code: "",
      isactive: true,
    } as UniversityProps,
    validate: {},
  });

  const segmentHandler = (v: string, t: string) => {
    if (t === "course") {
      let course: any = courseData.find((c: CourseDataProps) => c.name === v);
      setSelectedCourse({ ...course });
      setSelectedStream(null);
      setSelectedSemester(null);
    }
    if (t === "stream") {
      let stream = selectedCourse?.streams?.find(
        (c: StreamDataProps) => c.name === v
      );
      setSelectedStream({ ...stream });
      setSelectedSemester(null);
    }
    if (t === "semester") {
      let sem = selectedStream?.semesters?.find(
        (c: SemesterDataProps) => c.sem?.toString() == v
      );
      setSelectedSemester({ ...sem });
    }
    if (t === "subject") {
      setSelectedSubject(() =>
        selectedSemester?.subjects?.find(
          (c: SubjectDataProps) => c.name?.toString() == v
        )
      );
    }
  };

  const { onSubmit, reset, errors, values, setFieldValue, setValues } = form;

  const courseSelectHandler = (value: string) => {
    setCourse(value);
  };

  const courseHandler = () => {
    let sCourse: CourseDataProps = streamData?.course.find(
      (c: CourseProps) => c.name === course
    );
    if (!courseData.find((c) => c.id === sCourse.id)) {
      setCourseData((prev: CourseDataProps[]) => {
        prev.push(sCourse);
        return [...prev];
      });
    }
    setSelectedCourse(sCourse);
    setCourse(null);
  };
  const streamSelectHandler = (value: string) => {
    setStream(value);
  };

  const streamHandler = () => {
    if (selectedCourse) {
      let sStream: StreamDataProps = streamData?.stream.find(
        (c: StreamProps) => c.name === stream
      );
      if (!selectedCourse?.streams?.find((s) => s.id === sStream.id)) {
        if (selectedCourse?.streams) {
          selectedCourse?.streams.push(sStream);
        } else {
          selectedCourse.streams = [sStream];
        }
      }
      let index = courseData.findIndex((c) => c.id === selectedCourse.id);
      setCourseData((prev: CourseDataProps[]) => {
        prev[index] = selectedCourse;
        return [...prev];
      });
      setStream(null);
    }
  };

  const semesterSelectHandler = (value: string) => {
    setSemester(value);
  };

  const semesterHandler = () => {
    if (selectedCourse && selectedStream) {
      let sSemester: SemesterDataProps = streamData?.semester.find(
        (c: SemesterProps) => c.sem == semester
      );
      if (!selectedStream?.semesters?.find((s) => s.id === sSemester.id)) {
        if (selectedStream?.semesters) {
          selectedStream?.semesters.push(sSemester);
        } else {
          selectedStream.semesters = [sSemester];
        }
      }
      let index = courseData.findIndex((c) => c.id === selectedCourse.id);
      setCourseData((prev: CourseDataProps[]) => {
        let streamIndex = courseData[index].streams.findIndex(
          (c) => c.id === selectedStream.id
        );
        prev[index].streams[streamIndex] = selectedStream;
        return [...prev];
      });
      setSemester(null);
    }
  };

  const subjectSelectHandler = (value: string) => {
    setSubject(value);
  };

  const subjectHandler = () => {
    if (selectedCourse && selectedStream && selectedSemester) {
      let sSubject: SubjectDataProps = streamData?.subject.find(
        (c: SubjectProps) => c.name == subject
      );
      if (!selectedSemester?.subjects?.find((s) => s.id === sSubject.id)) {
        if (selectedSemester?.subjects) {
          selectedSemester?.subjects.push(sSubject);
        } else {
          selectedSemester.subjects = [sSubject];
        }
      }

      let index = courseData.findIndex((c) => c.id === selectedCourse.id);
      setCourseData((prev: any[]) => {
        let streamIndex = courseData[index].streams.findIndex(
          (c) => c.id === selectedStream.id
        );

        let semesterIndex: any = courseData[index].streams[
          streamIndex
        ].semesters?.findIndex((c) => c.id === selectedSemester.id);
        if (semesterIndex >= 0) {
          prev[index].streams[streamIndex].semesters[semesterIndex] =
            selectedSemester;
        }
        return [...prev];
      });
      setSubject(null);
    }
  };

  const resetHandler = () => {
    reset();
    setCourseData([]);
    setSelectedCourse(null);
    setSelectedStream(null);
    setSelectedSemester(null);
    setSelectedSubject(null);
  };

  const createCoursePayload = () => {
    return courseData?.map((c) => {
      return {
        id: c.id,
        streams: c.streams.map((strm: StreamDataProps) => {
          return {
            id: strm.id,
            semesters: strm?.semesters?.map((sem: SemesterDataProps) => {
              return {
                id: sem.id,
                subjects: sem.subjects?.map((sub) => {
                  return {
                    id: sub.id,
                  };
                }),
              };
            }),
          };
        }),
      };
    });
  };

  return (
    <Modal
      opened={props.isOpenModal}
      onClose={() => {
        reset();
        props.onReset();
      }}
      title={values.id ? "Update University" : "Update University"}
      centered
      size={"70%"}
      closeOnClickOutside={false}
    >
      <form
        onSubmit={onSubmit((payload) => {
          if (payload.id) {
            mutUpdateUniversity({
              payload: {
                universities: [{ ...payload, courses: createCoursePayload() }],
                mappingids: mappingId,
              },
              id: payload.id,
            });
          } else
            mutCreateUniversity({
              payload: {
                universities: [{ ...payload, courses: createCoursePayload() }],
              },
            });
        })}
      >
        <Grid justify={"flex-start"}>
          <Grid.Col span={6}>
            <TextInput
              label="University Name"
              placeholder="University Name"
              data-autofocus
              value={values.name}
              onChange={(event) =>
                setFieldValue("name", event.currentTarget.value)
              }
            />
          </Grid.Col>
          <Grid.Col span={6}>
            <TextInput
              label="University Code"
              placeholder="University Code"
              data-autofocus
              value={values.code}
              onChange={(event) =>
                setFieldValue("code", event.currentTarget.value)
              }
            />
          </Grid.Col>

          {/**
           *
           * Course controller
           */}
          <Grid.Col span={5}>
            <Select
              label="Add course"
              placeholder="Select a course"
              value={course ? course : ""}
              data={streamData?.course?.map((c: CourseProps) => c.name) || []}
              onChange={(e: any) => courseSelectHandler(e)}
            />
          </Grid.Col>
          <Grid.Col mt="xl" span={1}>
            <Button disabled={!course} onClick={courseHandler} fullWidth>
              Add
            </Button>
          </Grid.Col>
          <Grid.Col mt="xl" span={6}></Grid.Col>
          {courseData?.length ? (
            <Grid.Col span={3}>
              <Text>Courses</Text>
              <div className="pos-rlt">
                <SegmentedControl
                  orientation="vertical"
                  value={selectedCourse?.name || ""}
                  fullWidth
                  onChange={(v) => segmentHandler(v, "course")}
                  data={courseData?.map((c: CourseDataProps) => c.name || "")}
                />
                <div className="pos-abt pos-right-top">
                  <Trash className="c-pointer" color="red" />
                </div>
              </div>
            </Grid.Col>
          ) : null}
          {selectedCourse && (
            <>
              {/**
               *
               * Stream controller
               */}
              <Grid.Col span={5}>
                <Select
                  label={`Add stream for ${selectedCourse?.name}`}
                  placeholder="Select a Stream"
                  value={stream}
                  data={
                    streamData?.stream?.map((c: StreamProps) => c.name) || []
                  }
                  onChange={(e: any) => streamSelectHandler(e)}
                />
              </Grid.Col>
              <Grid.Col mt="xl" span={1}>
                <Button disabled={!stream} onClick={streamHandler} fullWidth>
                  Add
                </Button>
              </Grid.Col>
              <Grid.Col mt="xl" span={3}></Grid.Col>
              <Grid.Col span={3}>
                <Text>Streams</Text>
                <SegmentedControl
                  orientation="vertical"
                  defaultValue={""}
                  value={selectedStream?.name || ""}
                  fullWidth
                  onChange={(v) => segmentHandler(v, "stream")}
                  data={
                    selectedCourse?.streams?.map(
                      (c: StreamDataProps) => c.name || ""
                    ) || []
                  }
                />
              </Grid.Col>
              {selectedStream && (
                <>
                  {/**
                   *
                   * Semester controller
                   */}
                  <Grid.Col span={5}>
                    <Select
                      label={`Add semester for ${selectedStream?.name}`}
                      placeholder="Select a semester"
                      value={semester}
                      data={
                        streamData?.semester?.map((c: SemesterProps) =>
                          c.sem?.toString()
                        ) || []
                      }
                      onChange={(e: any) => semesterSelectHandler(e)}
                    />
                  </Grid.Col>
                  <Grid.Col mt="xl" span={1}>
                    <Button
                      disabled={!semester}
                      onClick={semesterHandler}
                      fullWidth
                    >
                      Add
                    </Button>
                  </Grid.Col>
                  <Grid.Col mt="xl" span={3}></Grid.Col>
                  <Grid.Col span={3}>
                    <Text>Semesters</Text>
                    <SegmentedControl
                      orientation="vertical"
                      defaultValue={""}
                      value={selectedSemester?.sem?.toString() || ""}
                      fullWidth
                      onChange={(v) => segmentHandler(v, "semester")}
                      data={
                        selectedStream?.semesters?.map(
                          (c: SemesterDataProps) => c.sem?.toString() || ""
                        ) || []
                      }
                    />
                  </Grid.Col>
                  {selectedSemester && (
                    <>
                      {/**
                       *
                       * Stream controller
                       */}
                      <Grid.Col span={5}>
                        <Select
                          label={`Add subject for semester ${selectedSemester?.sem}`}
                          placeholder="Select a Subject"
                          value={subject}
                          data={
                            streamData?.subject?.map(
                              (c: SubjectProps) => c.name
                            ) || []
                          }
                          onChange={(e: any) => subjectSelectHandler(e)}
                        />
                      </Grid.Col>
                      <Grid.Col mt="xl" span={1}>
                        <Button
                          disabled={!subject}
                          onClick={subjectHandler}
                          fullWidth
                        >
                          Add
                        </Button>
                      </Grid.Col>
                      <Grid.Col mt="xl" span={3}></Grid.Col>
                      <Grid.Col span={3}>
                        <Text>Subjects</Text>
                        <SegmentedControl
                          orientation="vertical"
                          defaultValue={""}
                          value={selectedSubject?.name || ""}
                          disabled={true}
                          fullWidth
                          onChange={(v) => segmentHandler(v, "subject")}
                          data={
                            selectedSemester?.subjects?.map(
                              (c: SubjectDataProps) => c.name || ""
                            ) || []
                          }
                        />
                      </Grid.Col>
                    </>
                  )}
                </>
              )}
            </>
          )}

          <Grid.Col span={12} offset={12}></Grid.Col>
          <Grid.Col span={3} offset={6}>
            <Button
              fullWidth
              onClick={resetHandler}
              mt="md"
              color={"red"}
              variant={"light"}
            >
              Clear
            </Button>
          </Grid.Col>
          <Grid.Col span={3}>
            <Button fullWidth type="submit" mt="md" variant="light">
              Submit
            </Button>
          </Grid.Col>
        </Grid>
      </form>
    </Modal>
  );
};
