import React from "react";
import { MutateFunction } from "react-query";
import { api } from "../api";
import { ChannelProps } from "../typing/channels";

export const getAllAppUsers = async () => {
    return await api.get(`app/users/list`);
};

// export const createChannel: MutateFunction<
//   ChannelProps,
//   Partial<ChannelProps>,
//   ChannelProps
// > = async (payload: any) => {
//   return api.post("channel/new", undefined, payload);
// };

// export const updateChannel: MutateFunction<
//   ChannelProps,
//   Partial<ChannelProps>,
//   { payload: ChannelProps; id: number }
// > = async ({ payload, id }) => {
//   console.log("id : ", id);
//   return api.patch(`channel/update/${id}`, undefined, payload);
// };

export const deleteUser: MutateFunction<string, any, string> = (id) => {
    return api.delete(`app/users/${id}`);
};
