import { Outlet, Navigate, RouteProps } from "react-router-dom";
import { useAppSelector } from "../redux/hooks";

type PrivateRouteProps = RouteProps & {
  fallbackTo: string;
};

export const PrivateRoute = ({ fallbackTo }: PrivateRouteProps) => {
  let isAuth = useAppSelector((state) => state.auth.isAuth);

  if (!isAuth) {
    return <Navigate to={fallbackTo} />;
  }
  return <Outlet />;
};
