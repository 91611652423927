import React from "react";

import "./App.css";

import { MantineProvider } from "@mantine/core";
import { QueryClient, QueryClientProvider } from "react-query";
import { Route, Routes } from "react-router-dom";

import { PrivateRoute } from "./routes/privateRoutes";
import AppRoutes from "./routes";
import { Login } from "./views/authentication/Login";
import { Provider } from "react-redux";
import { store } from "./redux/store";

const queryClient = new QueryClient();
function App() {
  return (
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <MantineProvider
          withGlobalStyles
          withNormalizeCSS
          theme={{
            breakpoints: {
              xs: 500,
              sm: 800,
              md: 1000,
              lg: 1200,
              xl: 1400,
            },
          }}
        >
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route element={<PrivateRoute fallbackTo="/login" />}>
              <Route path="/*" element={<AppRoutes />} />
            </Route>
          </Routes>
        </MantineProvider>
      </QueryClientProvider>
    </Provider>
  );
}

export default App;
