import { Button, Grid, Modal, Switch, TextInput } from "@mantine/core";
import { useForm } from "@mantine/form";
import { useEffect } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { updateAppSetup } from "../../queries/appSetup";
import { createHashtag, updateHashtag } from "../../queries/hashtagQueries";
import { AppSetupProps } from "../../typing/appSetup";

export const HashtagForm = (props: any) => {
  const queryClient = useQueryClient();

  const { mutate: mutUpdateHashtag } = useMutation(updateHashtag, {
    onSuccess: (result) => {
      queryClient.invalidateQueries();
      props.onReset();
    },
  });

  const { mutate: mutCreateHashtag } = useMutation(createHashtag, {
    onSuccess: (result) => {
      queryClient.invalidateQueries();
      props.onReset();
    },
  });
  useEffect(() => {
    if (props.data) setValues(props.data);
  }, [props.data]);
  const form = useForm({
    initialValues: {
      hashtag: "",
    } as HashtagProps,
    validate: {},
  });

  const { onSubmit, reset, errors, values, setFieldValue, setValues } = form;

  return (
    <Modal
      opened={props.isOpenModal}
      onClose={() => {
        reset();
        props.setOpenForm(false);
      }}
      title={values.id ? "Update Hashtag" : "Create Hashtag"}
      centered
      size={"xl"}
      closeOnClickOutside={false}
    >
      <form
        onSubmit={onSubmit((payload) => {
          if (payload.id) {
            mutUpdateHashtag({ payload, id: payload.id });
          } else mutCreateHashtag({ payload });
        })}
      >
        <Grid justify={"flex-start"}>
          <Grid.Col span={6}>
            <TextInput
              label="Hashtag"
              placeholder="type hashtag with prefix '#'"
              data-autofocus
              value={values.hashtag}
              onChange={(event) =>
                setFieldValue("hashtag", event.currentTarget.value)
              }
            />
          </Grid.Col>

          <Grid.Col span={3} offset={6}>
            <Button
              fullWidth
              onClick={reset}
              mt="md"
              color={"red"}
              variant={"light"}
            >
              Clear
            </Button>
          </Grid.Col>
          <Grid.Col span={3}>
            <Button fullWidth type="submit" mt="md" variant="light">
              Submit
            </Button>
          </Grid.Col>
        </Grid>
      </form>
    </Modal>
  );
};
