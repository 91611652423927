import React from "react";
import {
  TextInput,
  PasswordInput,
  Checkbox,
  Anchor,
  Paper,
  Title,
  Text,
  Container,
  Group,
  Button,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { useMutation, useQueryClient } from "react-query";
import {
  authLoginWithPassword,
  exchangeRefreshToken,
} from "../../queries/authQuery";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { login } from "../../redux/reducers/authReducer";
import { useNavigate } from "react-router-dom";
import { LocalStore } from "../../helper/LocalStore";

export function Login() {
  const disaptch = useAppDispatch();
  const navigate = useNavigate();
  const auth = useAppSelector((state) => state.auth.isAuth);
  const queryClient = useQueryClient();
  const { mutate: mutAuthLogin } = useMutation(authLoginWithPassword, {
    onSuccess: async (result) => {
      queryClient.invalidateQueries();

      if (result.status) {
        const response = await exchangeRefreshToken(result.refreshToken);
        LocalStore.saveRefreshToken(response.accessToken);
        disaptch(login());
        navigate("/channels");
      }
    },
  });

  const form = useForm({
    initialValues: {
      username: "",
      password: "",
    },
    validate: {},
  });

  const { values, setFieldValue, onSubmit, reset } = form;
  return (
    <form
      onSubmit={onSubmit((payload) => {
        mutAuthLogin(payload);
      })}
    >
      <Container size={420} my={40}>
        <Title
          align="center"
          sx={(theme) => ({
            fontFamily: `Greycliff CF, ${theme.fontFamily}`,
            fontWeight: 900,
          })}
        >
          Welcome back!
        </Title>
        {/* <Text color="dimmed" size="sm" align="center" mt={5}>
        Do not have an account yet?{" "}
        <Anchor<"a">
          href="#"
          size="sm"
          onClick={(event) => event.preventDefault()}
        >
          Create account
        </Anchor>
      </Text> */}

        <Paper withBorder shadow="md" p={30} mt={30} radius="md">
          <TextInput
            label="Username"
            placeholder="username"
            required
            value={values.username}
            onChange={(e) => setFieldValue("username", e.currentTarget.value)}
          />
          <PasswordInput
            label="Password"
            placeholder="password"
            required
            value={values.password}
            mt="md"
            onChange={(e) => setFieldValue("password", e.currentTarget.value)}
          />
          <Group position="apart" mt="md">
            <Checkbox label="Remember me" />
            <Anchor<"a">
              onClick={(event) => event.preventDefault()}
              href="#"
              size="sm"
            >
              Forgot password?
            </Anchor>
          </Group>
          <Button fullWidth mt="xl" type="submit">
            Sign in
          </Button>
        </Paper>
      </Container>
    </form>
  );
}
