import { MutateFunction } from "react-query";
import { api } from "../../../api";

export const getCollegeDetails = async () => {
  return await api.get(`college`);
};

export const updateCollege: MutateFunction<
  CollegeProps,
  Partial<CollegeProps>,
  { payload: CollegeProps; id: number }
> = async ({ payload, id }) => {
  return api.patch(`college/${id}`, undefined, payload);
};

export const createCollege: MutateFunction<
  unknown,
  CollegeProps,
  { payload: CollegeProps }
> = async ({ payload }) => {
  return api.post(`college`, undefined, payload);
};

export const deleteCollege: MutateFunction<
  unknown,
  any,
  { id: number }
> = async ({ id }) => {
  return api.delete(`college/${id}`);
};
