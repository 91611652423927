import { MutateFunction } from "react-query";
import { api } from "../../../api";

export const getStreamDetails = async () => {
  return await api.get(`stream`);
};

export const updateStream: MutateFunction<
  StreamProps,
  Partial<StreamProps>,
  { payload: StreamProps; id: number }
> = async ({ payload, id }) => {
  return api.patch(`stream/${id}`, undefined, payload);
};

export const createStream: MutateFunction<
  unknown,
  StreamProps,
  { payload: StreamProps }
> = async ({ payload }) => {
  return api.post(`stream`, undefined, payload);
};

export const deleteStream: MutateFunction<
  unknown,
  any,
  { id: number }
> = async ({ id }) => {
  return api.delete(`stream/${id}`);
};
